<template>
  <div class="menus">
      <div class="tian">
         <el-button 
             type="primary"
              plain
              icon="el-icon-plus"
              size="mini" 
              @click="addition()" 
              style=" float:left;" 
              v-hasPermi="['system:role:add']">
              添加
            </el-button>
      </div>

      <!-- ---------------添加模块----------------------------------- -->
        <template>
            <el-dialog title="添加角色" :visible.sync="dialogFormVisible" width="600px" append-to-body>
                <el-form >
                    <el-form-item label="角色名称"  :label-width="formLabelWidth" required>
                        <el-input v-model="roleName" placeholder="请输入角色名称" autocomplete="off"></el-input>
                    </el-form-item>
                   
                    <el-form-item label="权限字符串" :label-width="formLabelWidth" required>
                       <el-input v-model="roleKey" placeholder="请输入权限字符串" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="显示顺序" :label-width="formLabelWidth" required>
                         <el-input v-model="roleSort" placeholder="请输入显示顺序" autocomplete="off"></el-input>
                    </el-form-item>
                   <el-form-item label="菜单权限" :label-width="formLabelWidth" required>
                        <el-tree 
                                :data="treeForm" 
                                show-checkbox 
                                node-key="menuId"
                                ref="tree" 
                                default-expand-al="expand"
                                highlight-current 
                                :props="defaultProps" 
                                @check-change="handleCheckChange">
                            </el-tree>
                   </el-form-item>
                   
                    <el-form-item label="菜单状态" :label-width="formLabelWidth" required>
                        <el-radio-group v-model="status">
                            <el-radio label="0" >正常</el-radio>
                            <el-radio label="1" >停用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="备注" :label-width="formLabelWidth">
                       <el-input v-model="remark" placeholder="请输入备注" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="add()">确 定</el-button>
                </div>
            </el-dialog>
        </template>
       <!-- ---------------编辑模块-------------------:default-checked-keys =""---------------- -->
         <template>
            <el-dialog title="编辑角色" :visible.sync="editFormVisible" width="600px">
                <el-form >
                    <el-form-item label="角色名称"  :label-width="formLabelWidth" required>
                        <el-input v-model="editForm.roleName" placeholder="请输入角色名称" autocomplete="off"></el-input>
                    </el-form-item>
                   
                    <el-form-item label="权限字符串" :label-width="formLabelWidth" required>
                       <el-input v-model="editForm.roleKey" placeholder="请输入权限字符串" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="显示顺序" :label-width="formLabelWidth" required>
                         <el-input v-model="editForm.roleSort" placeholder="请输入显示顺序" autocomplete="off"></el-input>
                    </el-form-item>
                   <el-form-item label="菜单权限" :label-width="formLabelWidth" required>
                        <el-tree 
                                :data="treeForm" 
                                show-checkbox 
                                node-key="menuId"
                                ref="bian" 
                                :default-checked-keys ="checkedKeys"
                                default-expand-al="expand"
                                highlight-current 
                                :props="defaultProps" 
                                @check-change="bianCheckChange">
                            </el-tree>
                   </el-form-item>
                    <el-form-item label="菜单状态" :label-width="formLabelWidth" required>
                        <el-radio-group v-model="editForm.status">
                            <el-radio label="0" >正常</el-radio>
                            <el-radio label="1" >停用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="备注" :label-width="formLabelWidth" >
                       <el-input v-model="editForm.remark" placeholder="请输入备注" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="editFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="redactSubmint()">确 定</el-button>
                </div>
            </el-dialog>
        </template>

      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}"
        >
        <el-table-column prop="roleName"   label="角色名称"  width="180"> </el-table-column>
        <el-table-column prop="status"   label="角色状态" width="180"> 
            <template slot-scope="scope">
              <el-tag  v-if="scope.row.status=== '0'" >正常</el-tag>
              <el-tag type="danger" v-else >停用</el-tag>
            </template>
        </el-table-column>
        <el-table-column prop="createBy"  label="创建人"> </el-table-column>
        <el-table-column prop="createTime"  label="创建时间"> </el-table-column>
        <el-table-column label="操作" >
            <template slot-scope="scope" v-if="scope.row.roleId != 1">
                <el-button type="text" size="mini" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row)"   v-hasPermi="['system:role:edit']">编辑</el-button>
                <el-button type="text" size="mini" icon="el-icon-delete" @click="handleDelete(scope.$index, scope.row)"  v-hasPermi="['system:role:remove']">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    
        <el-pagination center background layout="prev, pager, next, sizes, total, jumper" :page-sizes="pageSizes"
        :page-size="pagesize" :page-count="pageCount" :total="total" :current-page.sync="pageNo" @current-change="handleCurrentChange"
        @size-change="handleSizeChange" ref="pagination" @next-click="pages" @prev-click="pages">
      </el-pagination>
    </div>
  
</template>



<script>
import request from '../../utils/request.js'
import { handleTree } from '../../utils/ruoyi.js'
  export default {
   
    data() {
      return {
        pagesize: 10, //分页默认一页显示15条数据
        currpage: 1, //分页默认显示第1页数据
        pageSizes:[10,30,50], //分页可选择一页多少条数据
        pageNo:1, //强行第一页
        total:0, //共多少条数据从接口获取
        pageCount:0, //共多少页数据从接口获取
        formLabelWidth: '120px',
        tableData: [],
        checkedKeys:[],
        dialogFormVisible:false,
        editFormVisible:false,
        editForm:[],
        roleName:'',  //角色名称
        roleKey:'',  //权限字符串
        roleSort:'', //显示顺序
        status:'',   //角色状态
        remark:'', //备注
        treeForm:[],  //添加树形数据存放
        treeData: "",
        treeDataValue: "",
        mineStatus: "",
        mineStatusValue: [],
        clickMsg:'1', //搜索数据分页和查询全部数据分页开关 1 查询所有数据  2 搜索数据  3 角色查询
        defaultProps: {
          children: "children",
          label: "menuName"
        }
      }
    },
    created() {
        this.info();
    },
     methods:{
      //select框值改变时候触发的事件
      // selectChange(e){
      //   var arrNew = [];
      //   var dataLength = this.mineStatusValue.length;
      //   console.log(this.mineStatusValue)
      //   var eleng = e.length;
      //   for(let i = 0; i< dataLength ;i++){
      //     for(let j = 0; j < eleng; j++){
      //       if(e[j] === this.mineStatusValue[i].label){
      //       arrNew.push(this.mineStatusValue[i])
      //       }
      //     }
      //   }
      //   this.$refs.tree.setCheckedNodes(arrNew);//设置勾选的值
      // },


    handleCheckChange() {
        // console.log(this.$refs.tree.getCheckedKeys())
        // console.log(this.$refs.tree.getHalfCheckedKeys())

        // let res = this.$refs.tree.getCheckedNodes(true,false); //这里两个true，1. 是否只是叶子节点 2. 是否包含半选节点（就是使得选择的时候不包含父节点）
        // let arrLabel = [];
       this.mineStatusValue = this.$refs.tree.getHalfCheckedKeys().concat(this.$refs.tree.getCheckedKeys())

        // console.log(res)
        // res.forEach(item => {
        //   // console.log(item)
        //   arrLabel.push(item.menuName);
        //   arr.push(item.menuId);
        // });
        // this.mineStatusValue = arr;
        // this.mineStatus = arrLabel;+
        // console.log('arr:'+JSON.stringify(arr))
        // console.log('arrLabel:'+arrLabel)
        //  console.log(this.mineStatusValue)
    },
     bianCheckChange(){
       this.mineStatusValue = this.$refs.bian.getHalfCheckedKeys().concat(this.$refs.bian.getCheckedKeys())
     },
 
       //获取列表数据  
      info(){
            this.clickMsg='1'; //搜索数据分页和查询全部数据分页开关 false 查询所有数据分页
            let dats={
                pageNum:this.currpage,
                pageSize:this.pagesize
                }
            return request({
                  url:'role/selectRoleList',
                  method:'get',
                  params:dats
              }).then(res=>{
              if(res.status == 200){
                  this.tableData = res.data.data
                  this.pageCount = res.data.pageBean.pageCount; //接口获取到的共多少页
                  this.total = res.data.pageBean.count; //接口获取到的共多少条

              }
              })
         },
         //添加获取树形数据
          parent(){
            return request({
                url:'menu/treeSelect',
                method:'get',
            }).then(res=>{
               if(res.status == 200){
                    this.treeForm = [];
                    // const menu = { menuId: 0, menuName: '主类目', children: [] };
                    let  menu = handleTree(res.data, "menuId");
                    this.treeForm= menu;
                    // console.log(this.treeForm)
                }
            }).catch(() => {
                
            })
        },
          addition(){
            this.empty()
            this.dialogFormVisible = true
            this.parent();
        },
        //清空添加表单
        empty(){
            this.roleName = "";
            this.roleKey = "";
            this.roleSort= "";
            this.mineStatus = "";
            this.status = 0;
            this.remark = ""
        },
        // 添加弹窗确定提交
        add(){
           if(this.roleName == ""){
                 this.$message({ message: '请输入角色名称', type: 'warning' }); 
           }else if(this.roleKey == ""){
                 this.$message({ message: '请输入权限字符串', type: 'warning' }); 
           }else if(this.roleSort == ""){
                 this.$message({ message: '请输入显示顺序', type: 'warning' }); 
           }else if(this.mineStatusValue == ""){
                 this.$message({ message: '请选择菜单权限', type: 'warning' }); 
           }else if(this.status== ""){
                 this.$message({ message: '请选择菜单状态', type: 'warning' }); 
           }else {
               let data={
                 roleName:this.roleName,
                 roleKey:this.roleKey,
                 roleSort:this.roleSort,
                 status:this.status,
                 menuIds:this.mineStatusValue,
                 remark:this.remark,
               }
                return request({
                    url:'role/insertRole',
                    method:'post',
                    data:data
                }).then(res=>{
                if(res.status == 200){
                    this.$message({
                          message: '添加成功',
                          type: 'success'
                    });
                    this.info();
                    this.empty();
                    this.dialogFormVisible= false
                    
                }
                }).catch(() => {
                    
                })
           }
           
        },
        
         //编辑弹窗
        handleEdit(index, row) {
           this.editFormVisible = true
           this.mineStatusValue = []
           this.checkedKeys = []
           this.editForm = Object.assign({}, row)
           this.parent();
          //  console.log(this.editForm)
           this.fulLine(this.editForm.roleId)
        //    this.editForm.menuId = row.menuId
           
        },
        //编辑获取整行数据
        fulLine(id){
           let datas = {
               roleId:id
           }
           return request({
                  url:'menu/roleMenuTreeSelect',
                  method:'get',
                  params:datas
              }).then(res=>{
                if(res.status == 200){
                    this.checkedKeys = res.data.checkedKeys
                    // console.log(this.checkedKeys)
                }
              })
        },
         // 编辑确定
        redactSubmint(){
          this.clickMsg='3';
            // console.log(this.editForm)
            let datas ={
                roleId:this.editForm.roleId,
                roleName:this.editForm.roleName,
                roleKey:this.editForm.roleKey,
                roleSort:this.editForm.roleSort,
                status:this.editForm.status,
                remark:this.editForm.remark,
                menuIds:this.mineStatusValue,
            }
             return request({
                    url:'role/updateRole',
                    method:'put',
                    data:datas
                }).then(res=>{
                if(res.status == 200){
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.info();
                    this.mineStatusValue = []
                    this.editFormVisible= false
                }
                }).catch(() => {
                    
                })
        }, 
        //删除
          handleDelete(index, row) {
            // console.log(index, row);
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data ={
                    roleId:row.roleId
                }
                return request({
                    url:'role/deleteRoleById',
                    method:'delete',
                    data:data
                }).then(res=>{
                if(res.status == 200){
                      this.$message({
                        message: '删除成功',
                        type: 'success'
                      });
                    this.info();
                 }
                }).catch(() => {
                    
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
         pages(){
            if(this.clickMsg=='1'){ //如果 clickMsg = 1 的话
               this.info(); //分页查询所有数据
            }else if(this.clickMsg=='2'){ //如果 clickMsg = 2 的话
               this.inquiry(); //分页查询搜索数据
            }else if(this.clickMsg=='3'){ //如果 clickMsg = 3 的话
               this.redactSubmint(); //分页角色查询数据
            }
         },

          /* 分页方法-------------- */
      handleCurrentChange(cpage) {
        this.currpage = cpage;
        this.pages(); //查询接口渲染数据
      },
      handleSizeChange(psize) {
        this.pagesize = psize;
        this.pages(); //查询接口渲染数据
      },
       
    },
  }
</script>

<style scoped>
  .el-table .warning-row {
     background: oldlace;
  }

  .el-table .success-row {
     background: #f0f9eb;
  }
  .tian{
        width: 100%;
        height: 60px;
   }
  .el-dialog{
        width: 500px !important;
  }
  .el-form{
        height: auto;
    }
  .el-pagination{
    text-align: right;
  }
  
::v-deep .el-form-item__content{
    text-align: left !important;
  }
</style>
 
